import { createSelector } from 'reselect';

export const getQuoteValues = (state) =>
  state.digitalQuoteAppState?.LandingPageState;

export const getQuoteId = createSelector(getQuoteValues, (getQuoteId) => {
  return getQuoteId?.quoteId;
});

export const getAuthtoken = createSelector(getQuoteValues, (getAuthtoken) => {
  return getAuthtoken?.authToken;
});

export const travAcordVersion = createSelector(
  getQuoteValues,
  (getTravAcordVersion) => {
    return getTravAcordVersion?.quoteDetails?.quote?.travAcordVersion;
  }
);
export const getQuoteDetailsdata = createSelector(
  getQuoteValues,
  (getQuoteDetailsdata) => {
    return getQuoteDetailsdata?.quoteDetails;
  }
);

export const getError = createSelector(getQuoteValues, (getErrorMessage) => {
  return getErrorMessage?.errorMessage;
});
