import { store } from '../store';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'universal-cookie';
import {
  SESSION_ID_HEADER_KEY,
  SMSESSION_HEADER_KEY,
  USER_TOKEN_HEADER_KEY,
  CORRELATION_ID_HEADER_KEY,
} from '../constants';

const cookies = new Cookies();

export const getAxiosOptions = (params) => {
  const {
    getAuthorizationHeader = true,
    getSmsessionHeader = true,
    getSessionIdHeader = true,
    getUsertokenHeader,
  } = params ?? {};
  const state = store.getState();
  const token = state.digitalQuoteAppState.LandingPageState.authToken;
  const oidc = cookies.get('x-trv-usertoken');
  const smSession = cookies.get('SMSESSION');
  const correlationId = uuidv4();
  const sessionId = cookies.get('TravSessionId');

  const options = {
    headers: {
      Accept: 'application/json',
      ...(getAuthorizationHeader && { Authorization: 'bearer ' + token }),
      'Content-Type': 'application/json',
      [CORRELATION_ID_HEADER_KEY]: correlationId,
      ...(getSessionIdHeader && { [SESSION_ID_HEADER_KEY]: sessionId }),
      ...(getSmsessionHeader && { [SMSESSION_HEADER_KEY]: smSession }),
      ...(getUsertokenHeader && { [USER_TOKEN_HEADER_KEY]: oidc }),
      'x-user': JSON.stringify({
        quoteId: state.digitalQuoteAppState.LandingPageState.quoteId,
      }),
    },
    withCredentials: true,
  };

  return options;
};
