import './BoatDetailsPageBanner.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { policyIconNameMap } from '../../../../utils';
import { Link, useNavigate } from 'react-router-dom';
import { ClickEvents } from '../../../../utils/analytics';
import Boat from './../../../../assets/widgets/Boat';

export const BoatDetailsPageBanner = (props) => {
  const {
    watercraftDesc,
    stateZip,
    agentName,
    agencyName,
    agencyPhone,
    agencyEmail,
    effectiveDate,
    expireDate,
  } = props ?? {};
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClick = () => {
    ClickEvents(t('GAConstants.home'));
    navigate(-1);
  };
  let formatPhone = '';
  agencyPhone !== null &&
    (formatPhone = `${agencyPhone?.slice(0, 7)}-${agencyPhone?.slice(7, 11)}`);
  return (
    <div data-testid="backgroundDetails" className="boat-details-banner">
      <div className="tds-container">
        <div className="tds-row">
          <div className="tds-col-md-10 tds-mx-auto">
            <div className="boat-details-banner-content">
              <div className="tds-row">
                <div className="tds-justify-content-start">
                  <p data-testid="boat-details-home-link-text">
                    <Link onClick={handleClick}>Home</Link>
                    &ensp;/&ensp;{watercraftDesc}
                  </p>
                </div>
              </div>
              <div className="tds-row">
                <h1
                  data-testid="boat-details-description-greeting-text"
                  className="tds-mb-0 tds-h1--slab tds-h1--small
                    tds-h1--semibold boat-details-banner-heading"
                >
                  Your {watercraftDesc} Quote Details
                </h1>
              </div>
              <div className="tds-row">
                <h2
                  data-testid="boat-details-watercraft-stateZip-text"
                  className="tds-mt-s tds-h3"
                >
                  {stateZip}
                </h2>
              </div>
              <div className="tds-row">
                <h5
                  className="tds-mt-s tds-mb-s boat-details-banner-agent-heading"
                  data-testid="boat-details-agent-heading"
                >
                  {t('yourQuote.agentDetail')}
                </h5>
              </div>
              {agentName && (
                <div className="tds-row" data-testid="boat-details-agent-name">
                  {agentName}
                </div>
              )}
              {agencyName && (
                <div
                  className="tds-row tds-mb-xxs"
                  data-testid="boat-details-agency-name"
                >
                  {agencyName}
                </div>
              )}
              {agencyPhone && (
                <div className="tds-row">
                  <div className="tds-mr-xxxs">{policyIconNameMap.Phone}</div>
                  <a
                    data-testid="boat-details-agent-phone"
                    href={'tel:' + formatPhone}
                    alt=""
                  >
                    {formatPhone}
                  </a>
                </div>
              )}
              {agencyEmail && (
                <div className="tds-row">
                  <div className="tds-mr-xxxs">
                    {policyIconNameMap.Envelope}
                  </div>
                  <a
                    data-testid="boat-details-agent-email"
                    href={'mailto:' + agencyEmail}
                    alt=""
                  >
                    {agencyEmail}
                  </a>
                </div>
              )}
              <div className="tds-row">
                <div
                  data-testid="boat-details-coverage-date-text"
                  className="tds-mt-m tds-x-small boat-details-date-card"
                >
                  {t('coverages.effective') + ' '}
                  {effectiveDate}
                  &ensp;
                  <span aria-label="to">{policyIconNameMap.ArrowRight}</span>
                  &ensp;
                  {expireDate}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        data-testid="boat-details-page-top-mountains"
        className="mountains"
        alt=""
      />
      <div
        data-testid="boat-details-page-flex-boat"
        className="boat tds-d-inline-flex"
        alt=""
      >
        <Boat width="250" />
      </div>
      <div
        data-testid="boat-details-page-bottom-waves"
        className="bottom-waves"
        alt=""
      />
    </div>
  );
};

BoatDetailsPageBanner.propTypes = {
  generalInfo: PropTypes.object,
  screenSize: PropTypes.any,
};
