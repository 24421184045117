import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalError } from './components/common/Error/GlobalError';
import { LoadingSpinner } from './components/common/Loading';
import './styles/app.scss';
import { DigitalQuoteRoutes } from './routes';
import { GACONSTANTS } from './constants.js';
import ReactGA from 'react-ga';
import { REACT_APP_GACODE } from './env';

ReactGA.initialize(REACT_APP_GACODE, {
  testMode: process.env.NODE_ENV === GACONSTANTS.GATYPE,
});

ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const { t } = useTranslation();
  const [isContentLoaded, setIsContentLoaded] = useState(true);

  useEffect(() => {
    setIsContentLoaded(true);
  });

  if (!isContentLoaded) {
    return <LoadingSpinner />;
  }

  if (isContentLoaded == null) {
    return (
      <GlobalError
        title={t('outage.blockingModalTitle')}
        description={t('globalError.serviceErrorDescription')}
      />
    );
  }

  return (
    <>
      <DigitalQuoteRoutes />
    </>
  );
}

export default App;
