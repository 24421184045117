import ReactGA from 'react-ga';
import { REACT_APP_GACODE } from '../env';
import { GACONSTANTS } from '../constants';

ReactGA.initialize(REACT_APP_GACODE, {
  testMode: process.env.NODE_ENV === GACONSTANTS.GATYPE,
});

const search = window.location.search;
const params = new URLSearchParams(search).get('userType');

const GAevent = (obj) => {
  ReactGA.event({
    category: 'SimpleQuote',
    action: 'Click',
    label: `${obj} (${params})`,
  });
};

export const ClickEvents = async (obj) => {
  GAevent(obj);
};

export const ClickEventsofPolicyDetails = async (obj) => {
  GAevent('View Definition ' + obj);
};
