import { ReactComponent as TrvPiBoat36 } from '@trv-ebus/tds-icons/dist/assets/icons/product/svg/trv-pi-boat-36.svg';
import { ReactComponent as TrvPiCar36 } from '@trv-ebus/tds-icons/dist/assets/icons/product/svg/trv-pi-car-36.svg';
import { ReactComponent as TrvPiCondo36 } from '@trv-ebus/tds-icons/dist/assets/icons/product/svg/trv-pi-condo-36.svg';
import { ReactComponent as TrvPiHome36 } from '@trv-ebus/tds-icons/dist/assets/icons/product/svg/trv-pi-home-36.svg';
import { ReactComponent as TrvPiRenter36 } from '@trv-ebus/tds-icons/dist/assets/icons/product/svg/trv-pi-renter-36.svg';
import { ReactComponent as TrvPiUmbrella36 } from '@trv-ebus/tds-icons/dist/assets/icons/product/svg/trv-pi-umbrella-36.svg';
import { ReactComponent as TrvPiValuables36 } from '@trv-ebus/tds-icons/dist/assets/icons/product/svg/trv-pi-valuables-36.svg';
import { ReactComponent as TrvPiLandlordCondo36 } from '@trv-ebus/tds-icons/dist/assets/icons/product/svg/trv-pi-landlord-condo-36.svg';
import { ReactComponent as TrvPiLandlordHome36 } from '@trv-ebus/tds-icons/dist/assets/icons/product/svg/trv-pi-landlord-home-36.svg';
import { ReactComponent as Play118 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/play-1-18.svg';
import { ReactComponent as Certificate118 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/certificate-1-18.svg';
import { ReactComponent as TrvLogoUmbrellaRed } from '@trv-ebus/tds-icons/dist/assets/icons/logos/svg/trv-logo-umbrella-red.svg';
import { ReactComponent as PersonCircle118 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/person-circle-1-18.svg';
import { ReactComponent as Clock118 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/clock-1-18.svg';
import { ReactComponent as Pages118 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/pages-1-18.svg';
import { ReactComponent as CaretCircleLeft124 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/caret-circle-left-1-24.svg';
import { ReactComponent as DollarCircle124 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/dollar-circle-1-24.svg';
import { ReactComponent as ArrowLeft124 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/arrow-left-1-24.svg';
import { ReactComponent as ArrowRight118 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/arrow-right-1-18.svg';
import { ReactComponent as Printer112 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/printer-1-12.svg';
import { ReactComponent as TrvLogo2colorBlackSmall } from '@trv-ebus/tds-icons/dist/assets/icons/logos/svg/trv-logo-2color-black-small.svg';
import { ReactComponent as Phone112 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/phone-1-12.svg';
import { ReactComponent as Envelope112 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/envelope-1-12.svg';
import { ReactComponent as PersonCircle124 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/person-circle-1-24.svg';
import { ReactComponent as Heart112 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/heart-1-12.svg';
import { ReactComponent as CheckCircle } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/check-circle-2-18.svg';
import { ReactComponent as QuestionCircle } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/question-circle-1-18.svg';
import { ReactComponent as CaretRightCircle118 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/caret-right-circle-1-18.svg';
import { ReactComponent as InfoCircle124 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/info-circle-1-24.svg';
import { ReactComponent as TrvPiYacht36 } from '@trv-ebus/tds-icons/dist/assets/icons/product/svg/trv-pi-yacht-36.svg';
import { ReactComponent as CaretRight118 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/caret-right-1-18.svg';

export const policyIconNameMap = {
  Boat: <TrvPiBoat36 className="boat-icon tds-icon-36 icon-color" />,
  Yacht: <TrvPiYacht36 className="boat-icon tds-icon-36 icon-color" />,
  Auto: <TrvPiCar36 className="car-icon tds-icon-36 icon-color" />,
  CONDO: <TrvPiCondo36 className="condo-icon tds-icon-36 icon-color" />,
  DWELLING: <TrvPiHome36 className="home-icon tds-icon-36 icon-color" />,
  TENANT: <TrvPiRenter36 className="renter-icon tds-icon-36 icon-color" />,
  Umbrella: (
    <TrvPiUmbrella36 className="umbrella-icon tds-icon-36 icon-color Umbrella-colour  " />
  ),
  UmbrellaTeaser: (
    <TrvPiUmbrella36 className="umbrella-icon tds-icon-36 icon-color   " />
  ),
  LANDLORDDWELLING: (
    <TrvPiLandlordHome36 className="tds-icon-36 tds-sq-policy__icon icon-color" />
  ),
  LANDLORDCONDO: (
    <TrvPiLandlordCondo36 className="tds-icon-36 tds-sq-policy__icon icon-color" />
  ),
  Personal_Article: (
    <TrvPiValuables36 className="valuables-icon tds-icon-36 icon-color" />
  ),
  Home: <TrvPiHome36 className="home-icon tds-icon-36 icon-color" />,
  PlayIcon: (
    <Play118
      className="tds-icon-18 tds-ml-xxs property-coverage-player discount-player"
      data-testid="player18"
    />
  ),
  LogoUmbrella: (
    <TrvLogoUmbrellaRed className="tds-icon-trv-logo-umbrella tds-sq-promo__header-logo" />
  ),
  Certificate: (
    <Certificate118 className="tds-icon-18 tds-sq-promo__item-icon" />
  ),
  PersonCircle: (
    <PersonCircle118 className="tds-icon-48 tds-sq-promo__item-icon" />
  ),
  Clock: <Clock118 className="tds-icon-48 tds-sq-promo__item-icon" />,
  Pages: <Pages118 className="tds-icon-48 tds-sq-promo__item-icon" />,
  CertificateLeft: <CaretCircleLeft124 className="tds-icon-24 caret-color" />,
  DollarCircle: <DollarCircle124 className="tds-icon-36 icon-color" />,
  ArrowLeft: <ArrowLeft124 className="tds-icon-18" />,
  ArrowRight: <ArrowRight118 className="tds-icon-18" />,
  Printer: (
    <Printer112
      className="tds-icon-12 tds-printer-icon"
      data-testid="printerIcon"
    />
  ),
  BlackSmall: (
    <TrvLogo2colorBlackSmall className="tds-icon-trv-logo-small trv-logo " />
  ),
  Phone: (
    <Phone112
      className="tds-icon-12 tds-sq-agent__icon"
      data-testid="phoneIcon"
    />
  ),
  Envelope: (
    <Envelope112
      className="tds-icon-12 tds-sq-agent__icon"
      data-testid="EmailIcon"
    />
  ),
  PersonCircle124: (
    <PersonCircle124 className="tds-icon-24 driver-person-icon" />
  ),
  Heart: <Heart112 className="tds-icon-12 status-color" alt="" />,
  CheckCircle: (
    <CheckCircle
      className="tds-icon-24 discount-icon discount--applied success-check-circle"
      alt="Discount Applied"
      data-testid="applied-discount"
    ></CheckCircle>
  ),
  QuestionCircle: (
    <QuestionCircle
      className="tds-icon-24 discount-icon discount--not-applied"
      alt="Discount Not Applied"
      data-testid="non-applied-discount"
    ></QuestionCircle>
  ),
  CareRight: (
    <CaretRightCircle118 className="tds-icon-18 tds-sq-next-nav__circle-right-icon" />
  ),
  InfoCircle: (
    <InfoCircle124
      className="tds-icon-24 tds-mb-xs tds-mx-xxxs"
      data-testid="info-circle"
    />
  ),
  Caret: <CaretRight118 className="tds-icon-18 watercraftcard-caret" />,
};
