import { createSelector } from 'reselect';

export const getQuoteDetails = (state) => {
  return state.quoteDetailsState?.QuoteDetailsState;
};

export const fetchQuoteDetails = createSelector(
  getQuoteDetails,
  (QuoteDetailsState) => {
    return QuoteDetailsState?.quoteDetails;
  }
);
export const fetchAutoPolicy = createSelector(
  getQuoteDetails,
  (QuoteDetailsState) => {
    return QuoteDetailsState?.quoteDetails?.quote?.autoPolicies;
  }
);

export const getCustomerFeedback = createSelector(
  getQuoteDetails,
  (QuoteDetailsState) => {
    return QuoteDetailsState?.customerFeedback;
  }
);

export const getPaymentOptionCheckState = (sublob) => {
  return createSelector(getQuoteDetails, (QuoteDetailsState) => {
    return QuoteDetailsState[sublob?.toLowerCase()]?.paymentOptionCheck;
  });
};

export const getAdditionalOptionsCheckState = (sublob) => {
  return createSelector(getQuoteDetails, (QuoteDetailsState) => {
    return QuoteDetailsState[sublob?.toLowerCase()]?.additionalOptions;
  });
};

export const getOtherCoveragesCheckState = createSelector(
  getQuoteDetails,
  (QuoteDetailsState) => {
    return QuoteDetailsState?.otherCoveragesOptions;
  }
);

export const getPaymentModeButtonStatus = (sublob) => {
  return createSelector(getQuoteDetails, (QuoteDetailsState) => {
    return QuoteDetailsState[sublob?.toLowerCase()]?.paymentModeButton;
  });
};

export const getSeePaymentOptionButtonStatus = (sublob) => {
  return createSelector(getQuoteDetails, (QuoteDetailsState) => {
    return QuoteDetailsState[sublob?.toLowerCase()]?.seePaymentOptions;
  });
};
