import { useTranslation } from 'react-i18next';
import './WaterCraftPolicyCoverage.scss';
import { DESKTOP_MIN_MEDIA_WIDTH } from '../../../../constants';
import { WatercraftCoverageTable } from '../WatercraftCoverageTable/WatercraftCoverageTable';
import { WatercraftCoverageList } from '../WatercraftCoverageList/WatercraftCoverageList';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

export const WaterCraftPolicyCoverage = (props) => {
  const { t } = useTranslation();
  const { waterCraftCoverages } = props ?? {};
  const policyCoverageList = waterCraftCoverages?.policyCoverages;
  const basicCoverageList = waterCraftCoverages?.basicCoverages;
  const commonEndorsementList = waterCraftCoverages?.commonEndorsements;
  const otherEndorsementList = waterCraftCoverages?.otherEndorsements;
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const getDimention = () => {
      setScreenSize(window.innerWidth);
    };
    window.addEventListener('resize', getDimention);
    return () => {
      window.removeEventListener('resize', getDimention);
    };
  }, [screenSize]);
  return (
    <div className="tds-container">
      <div className="tds-row">
        <div className="tds-col-md-10 tds-mx-auto">
          <div className="tds-row">
            <h2
              data-testid="watercraft-coverage-text"
              className="tds-mt-m tds-h2--slab"
            >
              {t('coverages.waterCraftCoverage')}
            </h2>
          </div>
          <div className="tds-row">
            <p data-testid="watercraft-coverage-description-text">
              {t('coverages.waterCraftCoverageDesc')}
            </p>
          </div>
          <div className="tds-row tds-d-flex watercraft-card-dark-blue">
            <h3 data-testid="watercraft-desc-text" className="tds-mr-auto">
              {waterCraftCoverages?.watercraftInfoDesc}
            </h3>
            <h3 data-testid="watercraft-premium">
              {waterCraftCoverages?.premium}
            </h3>
          </div>
          <div className="tds-row tds-d-flex">
            <div data-testid="watercraft-market-value">
              {t('coverages.marketValue')}
              {waterCraftCoverages?.marketValue}
            </div>
          </div>
          {screenSize >= DESKTOP_MIN_MEDIA_WIDTH ? (
            <div>
              <WatercraftCoverageTable
                policyCoverageList={policyCoverageList}
                basicCoverageList={basicCoverageList}
                commonEndorsementList={commonEndorsementList}
                otherEndorsementList={otherEndorsementList}
              />
            </div>
          ) : (
            <div>
              <WatercraftCoverageList
                policyCoverageList={policyCoverageList}
                basicCoverageList={basicCoverageList}
                commonEndorsementList={commonEndorsementList}
                otherEndorsementList={otherEndorsementList}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

WaterCraftPolicyCoverage.propTypes = {
  waterCraftCoverages: PropTypes.object,
};
