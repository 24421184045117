import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import sessionStorage from 'redux-persist/lib/storage/session';
import landingPageReducer from './landingPageReducers';
import policyDetailsReducer from './policyReducer';
import quoteReducers from './quoteReducer';
import quoteListReducer from './adminQuoteListReducer';
import rawDataLinkReducer from './rawDataLinkReducer';

const apiPersistConfig = {
  key: 'apiState',
  storage: sessionStorage,
};

const policyDetailsReducerConfig = {
  key: 'policyDetailsState',
  storage: sessionStorage,
};
const quoteReducerConfig = {
  key: 'quoteDetailsState',
  storage: sessionStorage,
};

const adminGetLinks = {
  key: 'adminState',
  storage: sessionStorage,
};
const rawDataLinks = {
  key: 'rawState',
  storage: sessionStorage,
};

const digitalQuoteAppReducers = combineReducers({
  LandingPageState: landingPageReducer,
});

const policyDetailsReducers = combineReducers({
  PolicyDetailsState: policyDetailsReducer,
});

const quoteReducer = combineReducers({
  QuoteDetailsState: quoteReducers,
});

const quoteListReducers = combineReducers({
  QuoteListState: quoteListReducer,
});

const rawDataReducers = combineReducers({
  RawDataState: rawDataLinkReducer,
});

const rootReducer = combineReducers({
  digitalQuoteAppState: persistReducer(
    apiPersistConfig,
    digitalQuoteAppReducers
  ),
  policyDetailsState: persistReducer(
    policyDetailsReducerConfig,
    policyDetailsReducers
  ),

  quoteDetailsState: (quoteReducerConfig, quoteReducer),
  quoteListState: persistReducer(adminGetLinks, quoteListReducers),
  rawDataState: persistReducer(rawDataLinks, rawDataReducers),
});

export default rootReducer;
