import './EndorsementDetailsTable.scss';
import { LandTransportationDetails } from '../LandTransportationDetails/LandTransportationDetails';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Modal from '../../../common/Modal/Modal';
import ModalBodyContent from '../../../common/Modal/ModalBodyContent';
import { ClickEventsofPolicyDetails } from '../../../../utils/analytics';

export const EndorsementDetailsTable = (props) => {
  const [showModal, setShowModal] = useState(false);
  const {
    displayName,
    limits,
    premium,
    landTransportEndorsement,
    shortContent,
  } = props ?? {};
  const openModal = () => {
    ClickEventsofPolicyDetails(displayName);
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <div className="tds-d-flex tds-justify-content-evenly tds-ml-xl tds-mr-xxl">
        <div className="tds-col-md-7">
          {shortContent === '' ? (
            <div
              className="coverage-display"
              data-testid="endorsement-coverage-name"
            >
              {displayName}
            </div>
          ) : (
            <div
              className="coverage-display"
              data-testid="endorsement-coverage-name"
            >
              <a
                className="tds-a"
                data-testid="option-name-link"
                onClick={(e) => {
                  e.preventDefault();
                  openModal();
                }}
                href="#"
              >
                {displayName}
              </a>
            </div>
          )}
        </div>
        <div className="tds-col-md-4">
          <div
            className="coverage-limits"
            data-testid="endorsement-coverage-limit"
          >
            {limits}
          </div>
        </div>
        <div className="tds-col-md-1">
          <div data-testid="endorsement-coverage-premium">{premium}</div>
        </div>
      </div>
      <div className="tds-ml-xl">
        {landTransportEndorsement?.map((props) => {
          const {
            beginningCity,
            beginningState,
            destinationCity,
            destinationState,
            totalMiles,
            dateOfDeparture,
            dateOfArrival,
          } = props ?? {};
          return (
            <div key={beginningCity}>
              <LandTransportationDetails
                beginningCity={beginningCity}
                beginningState={beginningState}
                destinationCity={destinationCity}
                destinationState={destinationState}
                totalMiles={totalMiles}
                dateOfDeparture={dateOfDeparture}
                dateOfArrival={dateOfArrival}
              />
            </div>
          );
        })}
      </div>
      {showModal && (
        <Modal
          title={displayName}
          size="large"
          opened={showModal}
          onClose={closeModal}
        >
          <ModalBodyContent
            description={shortContent}
            lob={'Boat'}
            width="100%"
            height="350"
          />
        </Modal>
      )}
    </>
  );
};

EndorsementDetailsTable.propTypes = {
  endorsementData: PropTypes.array,
};
