import './BoatDetailsPage.scss';
import { useEffect, useState } from 'react';
import { Header } from '../../../common/Header/Header';
import { Footer } from '../../../common/Footer/Footer';
import { WaterCraftPolicyCoverage } from '../WaterCraftPolicyCoverage/WaterCraftPolicyCoverage';
import { useLocation } from 'react-router-dom';
import { BoatDetailsPageBanner } from '../BoatDetailsPageBanner/BoatDetailsPageBanner';
import UmbrellaFooterPromo from '../../UmbrellaPromoSection/UmbrellaFooterPromo';
import { useBoatPolicy } from '../../../../hooks/useBoatPolicy';

export const BoatDetailsPage = () => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const location = useLocation();
  const coverageData = location?.state;
  const {
    agentName,
    agencyName,
    agencyPhone,
    agencyEmail,
    travAcordVersion,
    underwritingCompany,
    generatedDate,
    generalInfo,
  } = useBoatPolicy();
  const [printQuote] = useState(false);
  useEffect(() => {
    const getDimention = () => {
      setScreenSize(window.innerWidth);
    };
    window.addEventListener('resize', getDimention);
    return () => {
      window.removeEventListener('resize', getDimention);
    };
  }, [screenSize]);
  return (
    <>
      <div className="tds-container">
        <div className="tds-row">
          <div className="tds-col-md-12 tds-mx-auto">
            <section data-testid="boat-details-page-header-section">
              <header data-testid="boat-details-page-header">
                <Header type="DQP" travAcordVersion={travAcordVersion} />
              </header>
            </section>
          </div>
        </div>
      </div>
      <section data-testid="boat-details-page-main-section">
        <main data-testid="boat-details-page-main">
          <BoatDetailsPageBanner
            watercraftDesc={coverageData?.waterCraftdata?.watercraftInfoDesc}
            stateZip={coverageData?.waterCraftdata?.stateZip}
            agentName={agentName}
            agencyName={agencyName}
            agencyPhone={agencyPhone}
            agencyEmail={agencyEmail}
            effectiveDate={generalInfo?.effectiveDate}
            expireDate={generalInfo?.expirationDate}
          />
          <WaterCraftPolicyCoverage
            waterCraftCoverages={coverageData?.waterCraftdata}
          />
        </main>
      </section>
      <section data-testid="boat-details-page-footer-section">
        <header data-testid="boat-details-page-footer">
          <div
            data-testid="boat-details-page-promo-footer"
            className={printQuote ? 'print-display' : ''}
          >
            <UmbrellaFooterPromo />
          </div>
          <div className={printQuote ? 'print-display' : ''}>
            <Footer
              type="DQP"
              underwritingCompany={underwritingCompany}
              generatedDate={generatedDate}
            />
          </div>
        </header>
      </section>
    </>
  );
};
