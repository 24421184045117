import axios from 'axios';
import axiosRetry from 'axios-retry';
import { getAxiosOptions } from '../utils';
import { API_ROUTE, DEFAULT_RETRY_STRATEGY } from '../constants';

export class AdminQuoteListService {
  constructor(retryStrategy = DEFAULT_RETRY_STRATEGY) {
    axiosRetry(axios, retryStrategy);
  }

  async fetchQuoteLinks(key) {
    const isForward = sessionStorage.getItem('isForward');
    const forwardRef = isForward === null ? 'false' : isForward;
    const pageKey = key === undefined ? '' : key;
    const options = getAxiosOptions();
    const response = await axios.get(
      `${API_ROUTE.REACT_APP_SIMPLE_QUOTE_AUTH_URL}?recordCount=20&lastKey=${pageKey}&forward=${forwardRef}`,
      options
    );
    return response?.data;
  }

  async fetchRawQuote(quoteId) {
    const options = getAxiosOptions();
    const response = await axios.get(
      `${API_ROUTE.REACT_APP_SIMPLE_QUOTE_AUTH_URL}/${quoteId}/raw`,
      options
    );
    return response?.data;
  }
}
