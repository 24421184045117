import { persistStore } from 'redux-persist';
import rootReducer from './reducers/rootReducer';
import thunk from 'redux-thunk';
import { logger } from 'redux-logger';
import { configureStore } from '@reduxjs/toolkit';

const middleware = [thunk];

if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: middleware,
});

export const persistor = persistStore(store);
