import {
  GET_RAW_DATA_START,
  GET_RAW_DATA_SUCCESS,
  GET_RAW_DATA_ERROR,
} from '../actions/actionTypes';

const INITIAL_STATE = {
  rawData: null,
  isLoading: false,
  errorMessage: null,
};
const rawDataLinkReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_RAW_DATA_START:
      return {
        ...INITIAL_STATE,
        isLoading: true,
        errorMessage: null,
      };
    case GET_RAW_DATA_SUCCESS:
      return {
        ...state,
        rawData: action.payload,
        isLoading: false,
        errorMessage: null,
      };
    case GET_RAW_DATA_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default rawDataLinkReducer;
