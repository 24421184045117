import { UPDATE_CUSTOMER_FEEDBACK, UPDATE_SUBLOB_STATUS } from './actionTypes';

export const setCustomerFeedback = (data) => ({
  type: UPDATE_CUSTOMER_FEEDBACK,
  payload: data,
});

export const setSublobDataState = (data) => ({
  type: UPDATE_SUBLOB_STATUS,
  payload: data,
});
