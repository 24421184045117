// #region imports
import './UmbrellaFooterPromo.scss';
import { useTranslation } from 'react-i18next';
import { policyIconNameMap } from '../../../utils';
// #endregion

const UmbrellaFooterPromo = () => {
  const { t } = useTranslation();
  return (
    // #region UI
    <>
      <div className="tds-sq-promo tds-pt-xl tds-pb-xxl tds-mt-xxxl">
        <section className="tds-container-fluid">
          <div className="tds-text-align-center ">
            <div className="tds-row tds-no-gutters tds-justify-content-center tds-text-align-center">
              <div className="tds-col-xs-12 tds-col-lg-10">
                <div
                  className="tds-justify-content-sm-center"
                  data-testid="mainLogo"
                  alt=""
                >
                  <div className="tds-pt-xl">
                    {policyIconNameMap.LogoUmbrella}
                  </div>
                </div>
                <h2
                  className="tds-h1--slab tds-h1--semibold"
                  data-testid="mainHeading"
                >
                  {t('umbrellaFooter.umbrellaFooterMainHeading')}
                  <sup className="superscript-trademark">
                    {t('umbrellaFooter.superscriptTradeMark')}
                  </sup>
                </h2>
                <p className="tds-large" data-testid="subHeading">
                  {t('umbrellaFooter.umbrellaFooterSubHeading')}
                </p>
              </div>
            </div>
          </div>
          <div className="tds-row tds-mt-xl tds-ml-l tds-no-gutters">
            <div className="tds-col-lg-6">
              <div
                className="tds-row tds-mb-xxl tds-flex-wrap tds-align-items-start "
                data-testid="personcircle-icon"
                alt=""
              >
                <div className="tds-mt-m tds-mr-m">
                  {policyIconNameMap.PersonCircle}
                </div>
                <div className="tds-col tds-align-items-start">
                  <p className="tds-large tds-m-0 tds-h1--semibold">
                    {t(
                      'umbrellaFooter.umbrellaFooterHeading.umbrellaFooterHeading3'
                    )}
                  </p>
                  <p>{t('umbrellaFooter.umbrellaParas.umbrellaPara3')}</p>
                </div>
              </div>
              <div
                className="tds-row tds-mb-xxl tds-flex tds-align-items-start"
                data-testid="pages-icon"
                alt=""
              >
                <div className="tds-mt-m tds-mr-m">
                  {policyIconNameMap.Pages}
                </div>
                <div className="tds-col tds-align-items-start">
                  <p className="tds-large tds-m-0 tds-h1--semibold">
                    {t(
                      'umbrellaFooter.umbrellaFooterHeading.umbrellaFooterHeading4'
                    )}
                  </p>
                  <p>{t('umbrellaFooter.umbrellaParas.umbrellaPara4')}</p>
                </div>
              </div>
            </div>
            <div className="tds-col-lg-6">
              <div
                className="tds-row tds-mb-xxl tds-flex tds-align-items-start"
                data-testid="clock-icon"
                alt=""
              >
                <div className="tds-mt-m tds-mr-m">
                  {policyIconNameMap.Clock}
                </div>
                <div className="tds-col tds-flex tds-align-items-start">
                  <p className="tds-large tds-m-0 tds-h1--semibold">
                    {t(
                      'umbrellaFooter.umbrellaFooterHeading.umbrellaFooterHeading2'
                    )}
                  </p>
                  <p>{t('umbrellaFooter.umbrellaParas.umbrellaPara2')}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
    // #endregion
  );
};
export default UmbrellaFooterPromo;
