import { useQuoteService } from './useQuoteService';

export const useBoatPolicy = () => {
  const { getQuote } = useQuoteService();
  const agentName = getQuote?.quote?.agent?.name;
  const agencyName = getQuote?.quote?.agent?.agency;
  const agencyPhone = getQuote?.quote?.agent?.phone;
  const agencyEmail = getQuote?.quote?.agent?.email;
  const previewButton = getQuote?.quote?.agent?.preview;
  const boatPolicyData = getQuote?.quote?.boatPolicies[0];
  const underwritingCompany = getQuote?.quote?.underwritingCompany;
  const generatedDate = getQuote?.dateGenerated;
  const generalInfo = {
    effectiveDate: boatPolicyData?.effectiveDate,
    expirationDate: boatPolicyData?.expirationDate,
    preferredName: getQuote?.quote?.customer?.preferredName,
    lob: boatPolicyData?.sublob,
  };
  const travAcordVersion = getQuote?.quote?.travAcordVersion;
  const waterCraftList = boatPolicyData?.watercrafts;
  const operatorInfoList = boatPolicyData?.operators;
  const discounts = boatPolicyData?.discounts;
  const totalSavings = boatPolicyData?.totalSavings;
  const paymentOptionList = boatPolicyData?.paymentOptionList;
  const agentComment = getQuote?.quote?.agent?.comments;
  const totalPremium = waterCraftList?.reduce(
    (acc, cur) => acc + Number(cur.premium.slice(1)),
    0
  );

  return {
    agentName,
    agencyName,
    agencyPhone,
    agencyEmail,
    previewButton,
    underwritingCompany,
    generatedDate,
    generalInfo,
    operatorInfoList,
    discounts,
    totalSavings,
    paymentOptionList,
    agentComment,
    totalPremium,
    waterCraftList,
    travAcordVersion,
  };
};
