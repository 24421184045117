import axios from 'axios';
import axiosRetry from 'axios-retry';
import { getAxiosOptions } from '../utils/getAxiosOptions';
import { DEFAULT_RETRY_STRATEGY, API_ROUTE } from '../constants';

export class Auth {
  constructor(retryStrategy = DEFAULT_RETRY_STRATEGY) {
    axiosRetry(axios, retryStrategy);
  }

  async fetchAuth(data) {
    const option = getAxiosOptions();
    const response = await axios.get(
      `${API_ROUTE.REACT_APP_SIMPLE_QUOTE_AUTH_URL}/${data.quoteId}`,
      option
    );

    return response?.data;
  }
}
