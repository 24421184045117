import {
  SAVE_QUOTEID,
  UPDATE_QUOTEID_AUTHTOKEN,
  UPDATE_QUOTEID_AUTHTOKEN_ERROR,
  UPDATE_QUOTEDETAILS,
} from '../actions/actionTypes';

const initialState = {
  quoteId: null,
  authToken: null,
  errorMessage: null,
  quoteDetails: null,
};

const landingPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_QUOTEID:
      return {
        ...initialState,
        quoteId: action.payload,
      };
    case UPDATE_QUOTEID_AUTHTOKEN:
      return {
        ...state,
        quoteDetails: action.payload.quoteDetails,
        authToken: action.payload.authToken,
        errorMessage: null,
      };
    case UPDATE_QUOTEID_AUTHTOKEN_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case UPDATE_QUOTEDETAILS:
      return {
        ...state,
        quoteDetails: action.payload,
        errorMessage: null,
      };
    default:
      return state;
  }
};

export default landingPageReducer;
