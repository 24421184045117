import {
  FETCH_POLICY_DETAILS_START,
  FETCH_POLICY_DETAILS_SUCCESS,
  FETCH_POLICY_DETAILS_ERROR,
} from '../actions/actionTypes';

const INITIAL_STATE = {
  policyDetails: [],
  errorMessage: null,
  isLoading: false,
};

const policyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_POLICY_DETAILS_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case FETCH_POLICY_DETAILS_SUCCESS:
      return {
        ...state,
        policyDetails: [...state.policyDetails, action.payload],
        isLoading: false,
      };
    case FETCH_POLICY_DETAILS_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default policyReducer;
