import { useTranslation } from 'react-i18next';
import { EndorsementDetailsTable } from '../EndorsementDetailsTable/EndorsementDetailsTable';
import PropTypes from 'prop-types';

export const WatercraftCoverageTable = (props) => {
  const { t } = useTranslation();
  const {
    policyCoverageList,
    basicCoverageList,
    commonEndorsementList,
    otherEndorsementList,
  } = props ?? {};
  return (
    <>
      <div>
        {/* Table Header */}
        {(policyCoverageList?.length > 0 ||
          basicCoverageList?.length > 0 ||
          commonEndorsementList?.length > 0 ||
          otherEndorsementList?.length > 0) && (
          <div className="tds-d-flex tds-justify-content-evenly tds-ml-xl tds-mr-xxl table-labels border-bottom">
            <div className="tds-col-md-7">
              <div data-testid="watercraft-coverage-label" className="tds-mt-l">
                {t('coverages.coverageLabel')}
              </div>
            </div>
            <div className="tds-col-md-4">
              <div data-testid="watercraft-limit-label" className="tds-mt-l">
                {t('coverages.limitLabel')}
              </div>
            </div>
            <div className="tds-col-md-1">
              <div data-testid="watercraft-premium-label" className="tds-mt-l">
                {t('coverages.premiumLabel')}
              </div>
            </div>
          </div>
        )}
        {policyCoverageList?.length > 0 && (
          <>
            <h4
              data-testid="watercraft-policy-coverage"
              className="tds-h4 tds-mt-s tds-ml-xl tds-mr-xxl watercraft-card-dark-blue border-bottom"
            >
              {t('coverages.policyCoverage')}
            </h4>
            <div data-testid="policy-coverage-grid">
              {policyCoverageList?.map((prop) => {
                const { displayName, limits, premium, shortContent } =
                  prop ?? {};
                return (
                  <div key={displayName + limits + premium}>
                    <EndorsementDetailsTable
                      displayName={displayName}
                      limits={limits}
                      premium={premium}
                      shortContent={shortContent}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}
        {basicCoverageList?.length > 0 && (
          <>
            <h4
              data-testid="watercraft-basic-coverage"
              className="tds-h4 tds-mt-s tds-ml-xl tds-mr-xxl watercraft-card-dark-blue border-bottom"
            >
              {t('coverages.basicCoverage')}
            </h4>
            <div data-testid="basic-coverage-grid">
              {basicCoverageList?.map((prop) => {
                const { displayName, limits, premium, shortContent } =
                  prop ?? {};
                return (
                  <div key={displayName + limits + premium}>
                    <EndorsementDetailsTable
                      displayName={displayName}
                      limits={limits}
                      premium={premium}
                      shortContent={shortContent}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}
        {commonEndorsementList?.length > 0 && (
          <>
            <h4
              data-testid="watercraft-commonEndorsements"
              className="tds-h4 tds-mt-s tds-ml-xl tds-mr-xxl watercraft-card-dark-blue border-bottom"
            >
              {t('coverages.commonEndorsements')}
            </h4>
            <div data-testid="common-endorsement-grid">
              {commonEndorsementList?.map((props) => {
                const { displayName, limits, premium, shortContent } =
                  props ?? {};
                return (
                  <div key={displayName + limits + premium}>
                    <EndorsementDetailsTable
                      displayName={displayName}
                      limits={limits}
                      premium={premium}
                      shortContent={shortContent}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}
        {otherEndorsementList?.length > 0 && (
          <>
            <h4
              data-testid="watercraft-otherEndorsements"
              className="tds-h4 tds-mt-s tds-ml-xl tds-mr-xxl watercraft-card-dark-blue border-bottom"
            >
              {t('coverages.otherEndorsements')}
            </h4>
            <div data-testid="other-endorsement-table">
              {otherEndorsementList?.map((props) => {
                const {
                  displayName,
                  limits,
                  premium,
                  landTransportEndorsement,
                  shortContent,
                } = props ?? {};
                return (
                  <div key={displayName + limits + premium}>
                    <EndorsementDetailsTable
                      displayName={displayName}
                      limits={limits}
                      premium={premium}
                      landTransportEndorsement={landTransportEndorsement}
                      shortContent={shortContent}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

WatercraftCoverageTable.propTypes = {
  watercraftCoverages: PropTypes.array,
};
