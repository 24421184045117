import { useTranslation } from 'react-i18next';
import { EndorsementDetailsList } from '../EndorsementDetailsList/EndorsementDetailsList';
import PropTypes from 'prop-types';

export const WatercraftCoverageList = (props) => {
  const { t } = useTranslation();
  const {
    policyCoverageList,
    basicCoverageList,
    commonEndorsementList,
    otherEndorsementList,
  } = props ?? {};
  return (
    <>
      {policyCoverageList?.length > 0 && (
        <>
          <h4
            data-testid="watercraft-policy-coverage"
            className="tds-h4 tds-mt-l watercraft-card-dark-blue"
          >
            {t('coverages.policyCoverage')}
          </h4>
          <div data-testid="policy-coverage-list">
            {policyCoverageList?.map((props) => {
              const {
                displayName,
                limits,
                premium,
                landTransportEndorsement,
                shortContent,
              } = props ?? {};
              return (
                <div className="table-labels" key={displayName}>
                  <EndorsementDetailsList
                    displayName={displayName}
                    limits={limits}
                    premium={premium}
                    landTransportEndorsement={landTransportEndorsement}
                    shortContent={shortContent}
                  />
                </div>
              );
            })}
          </div>
        </>
      )}
      {basicCoverageList?.length > 0 && (
        <>
          <h4
            data-testid="watercraft-basic-coverage"
            className="tds-h4 tds-mt-l watercraft-card-dark-blue"
          >
            {t('coverages.basicCoverage')}
          </h4>
          <div data-testid="basic-coverage-list">
            {basicCoverageList?.map((props) => {
              const {
                displayName,
                limits,
                premium,
                landTransportEndorsement,
                shortContent,
              } = props ?? {};
              return (
                <div className="table-labels" key={displayName}>
                  <EndorsementDetailsList
                    displayName={displayName}
                    limits={limits}
                    premium={premium}
                    landTransportEndorsement={landTransportEndorsement}
                    shortContent={shortContent}
                  />
                </div>
              );
            })}
          </div>
        </>
      )}
      {commonEndorsementList?.length > 0 && (
        <>
          <h4
            data-testid="watercraft-commonEndorsement-coverage"
            className="tds-h4 tds-mt-l watercraft-card-dark-blue"
          >
            {t('coverages.commonEndorsements')}
          </h4>
          <div data-testid="commonEndorsement-coverage-list">
            {commonEndorsementList?.map((props) => {
              const {
                displayName,
                limits,
                premium,
                landTransportEndorsement,
                shortContent,
              } = props ?? {};
              return (
                <div className="table-labels" key={displayName}>
                  <EndorsementDetailsList
                    displayName={displayName}
                    limits={limits}
                    premium={premium}
                    landTransportEndorsement={landTransportEndorsement}
                    shortContent={shortContent}
                  />
                </div>
              );
            })}
          </div>
        </>
      )}
      {otherEndorsementList?.length > 0 && (
        <>
          <h4
            data-testid="watercraft-otherEndorsement-coverage"
            className="tds-h4 watercraft-card-dark-blue"
          >
            {t('coverages.otherEndorsements')}
          </h4>
          <div data-testid="otherEndorsement-coverage-list">
            {otherEndorsementList?.map((props) => {
              const {
                displayName,
                limits,
                premium,
                landTransportEndorsement,
                shortContent,
              } = props ?? {};
              return (
                <div className="table-labels" key={displayName}>
                  <EndorsementDetailsList
                    displayName={displayName}
                    limits={limits}
                    premium={premium}
                    landTransportEndorsement={landTransportEndorsement}
                    shortContent={shortContent}
                  />
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

WatercraftCoverageList.propTypes = {
  watercraftCoverages: PropTypes.array,
};
