import './i18n';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store, persistor } from './store';
import { CookiesProvider } from 'react-cookie';
import App from './App';
import './styles/app.scss';

function AppContainer() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </PersistGate>
    </Provider>
  );
}

export default AppContainer;
