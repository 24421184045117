import PropTypes from 'prop-types';
import { TdsModal, TdsModalButtons, TdsButton } from '@trv-tds/react';

const Modal = (props) => {
  return (
    // #region Modal UI
    <TdsModal
      data-testid={props.id}
      opened={props.opened}
      id={props.id}
      dialog-title={props.title}
      size={props.size}
      onTdsClose={props.onClose}
      initialFocus={props.initialFocus}
    >
      {props.children}
      <TdsModalButtons slot="footer">
        <TdsButton variant="tertiary" data-dismiss="modal">
          Close
        </TdsButton>
      </TdsModalButtons>
    </TdsModal>
    // #endregion
  );
};

// #region PropTypes
Modal.propTypes = {
  title: PropTypes.string,
  size: PropTypes.string,
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  initialFocus: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
// #endregion

export default Modal;
