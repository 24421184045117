import { useTranslation } from 'react-i18next';
import { LandTransportationDetails } from '../LandTransportationDetails/LandTransportationDetails';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Modal from '../../../common/Modal/Modal';
import ModalBodyContent from '../../../common/Modal/ModalBodyContent';

export const EndorsementDetailsList = (props) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const {
    displayName,
    limits,
    premium,
    landTransportEndorsement,
    shortContent,
  } = props ?? {};
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <div
        className="tds-ml-xs tds-mt-m"
        data-testid="endorsement-coverage-name"
      >
        {t('coverages.coverageLabel')}
        {shortContent === '' ? (
          <div data-testid="option-name-link"> {displayName}</div>
        ) : (
          <div>
            <a
              className="tds-a"
              data-testid="option-name-link"
              onClick={(e) => {
                e.preventDefault();
                openModal();
              }}
              href="#"
            >
              {displayName}
            </a>
          </div>
        )}
      </div>
      {landTransportEndorsement?.map((props) => {
        const {
          beginningCity,
          beginningState,
          destinationCity,
          destinationState,
          totalMiles,
          dateOfDeparture,
          dateOfArrival,
        } = props ?? {};
        return (
          <div key={beginningCity}>
            <LandTransportationDetails
              beginningCity={beginningCity}
              beginningState={beginningState}
              destinationCity={destinationCity}
              destinationState={destinationState}
              totalMiles={totalMiles}
              dateOfDeparture={dateOfDeparture}
              dateOfArrival={dateOfArrival}
            />
          </div>
        );
      })}
      <div
        className="tds-ml-xs tds-mt-m"
        data-testid="endorsement-coverage-limit"
      >
        {t('coverages.limitLabel')}
        <div>{limits}</div>
      </div>
      <div
        className="tds-ml-xs tds-mt-m"
        data-testid="endorsement-coverage-premium"
      >
        {t('coverages.premiumLabel')}
        <div className="tds-mb-m">{premium}</div>
      </div>
      <div className="border-bottom"></div>
      {showModal && (
        <Modal
          title={displayName}
          size="large"
          opened={showModal}
          onClose={closeModal}
        >
          <ModalBodyContent
            description={shortContent}
            lob={'Boat'}
            width="100%"
            height="350"
          />
        </Modal>
      )}
    </>
  );
};

EndorsementDetailsList.propTypes = {
  endorsementData: PropTypes.array,
};
