import {
  SAVE_QUOTEID,
  UPDATE_QUOTEID_AUTHTOKEN,
  UPDATE_QUOTEID_AUTHTOKEN_ERROR,
  UPDATE_QUOTEDETAILS,
} from './actionTypes';
import { Auth } from '../services';
import moment from 'moment';
import { REACT_APP_JWT_KEY } from '../env';

const jsonwebtoken = require('jwt-encode');
const auth = new Auth();

const createToken = async (quoteId) => {
  const date = moment(new Date().toUTCString()).add(2, 'hours').unix();
  return jsonwebtoken({ quoteId: quoteId, issuedAt: date }, REACT_APP_JWT_KEY);
};

const isZipValid = (enteredZipCode, authZipCodes) => {
  for (let authZipCode of authZipCodes) {
    if (enteredZipCode.substr(0, 5) === authZipCode.substr(0, 5)) {
      return true;
    }
  }
  return false;
};

export const saveQuoteId = (quoteId) => ({
  type: SAVE_QUOTEID,
  payload: quoteId,
});

export const updateAuthtokenSuccess = (authtoken) => ({
  type: UPDATE_QUOTEID_AUTHTOKEN,
  payload: authtoken,
});

export const updateAuthtokenError = (errorMessage) => ({
  type: UPDATE_QUOTEID_AUTHTOKEN_ERROR,
  payload: errorMessage,
});

export const updateQuote = (quotedata) => ({
  type: UPDATE_QUOTEDETAILS,
  payload: quotedata,
});

export const updateQuoteIdAuthtoken = (quoteZipdata) => async (dispatch) => {
  try {
    const response = await auth.fetchAuth(quoteZipdata);
    const quoteZipCodes = response?.quote?.authZipCodes;
    if (isZipValid(quoteZipdata.zipCode, quoteZipCodes)) {
      const token = await createToken(quoteZipdata.quoteId);
      dispatch(
        updateAuthtokenSuccess({
          quoteDetails: response,
          authToken: token,
        })
      );
    } else {
      dispatch(updateAuthtokenError('Unauthorized'));
    }
  } catch (err) {
    dispatch(updateAuthtokenError('Unauthorized'));
  }
};

export const updateAuthforQuote = (quoteId) => async (dispatch) => {
  try {
    const response = await auth.fetchAuth({
      quoteId: quoteId,
    });

    const token = await createToken(quoteId);
    dispatch(
      updateAuthtokenSuccess({
        quoteDetails: response,
        authToken: token,
      })
    );
    return response;
  } catch (err) {
    dispatch(updateAuthtokenError(err));
  }
};

export const checkValidation = () => async (dispatch) => {
  dispatch(updateAuthtokenSuccess({ quoteDetails: null, authToken: null }));
};
