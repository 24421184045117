import { useSelector, useDispatch } from 'react-redux';
import { checkValidation } from '../actions/landingActions';
import {
  fetchQuoteDetails,
  fetchAutoPolicy,
  getCustomerFeedback,
  getPaymentOptionCheckState,
  getAdditionalOptionsCheckState,
  getOtherCoveragesCheckState,
  getPaymentModeButtonStatus,
  getSeePaymentOptionButtonStatus,
} from '../../src/selectors/quoteServiceSelector';
import {
  getAuthtoken,
  getQuoteDetailsdata,
} from '../selectors/getQuoteSelectors';
import jwt_decode from 'jwt-decode';
import { USERTYPE } from '../constants';
import {
  // quoteServiceDetails,
  setCustomerFeedback,
  setSublobDataState,
} from '../actions/quoteServiceAction';
import moment from 'moment';

export const useQuoteService = (sublob) => {
  const dispatch = useDispatch();
  const getQuote = useSelector(getQuoteDetailsdata);
  const authtoken = useSelector(getAuthtoken);
  const getQuoteData = useSelector(fetchQuoteDetails);
  const autoPolicyDetails = useSelector(fetchAutoPolicy);
  const customerFeedback = useSelector(getCustomerFeedback);
  const paymentOptionsCheckState = useSelector(
    getPaymentOptionCheckState(sublob?.toLowerCase())
  );
  const additionalOptionsCheckState = useSelector(
    getAdditionalOptionsCheckState(sublob?.toLowerCase())
  );
  const otherCoveragesCheckState = useSelector(getOtherCoveragesCheckState);
  const paymentModeButtonStatus = useSelector(
    getPaymentModeButtonStatus(sublob?.toLowerCase())
  );
  const seePaymentOptionButtonStatus = useSelector(
    getSeePaymentOptionButtonStatus(sublob?.toLowerCase())
  );

  const quoteDetails = async (quoteId) => {
    if (authtoken !== null) {
      let payload = jwt_decode(authtoken);
      let dateNow = moment(new Date().toUTCString()).unix();
      const usertype = USERTYPE.QUOTESPAGE.includes(quoteId.userType);
      if (payload.issuedAt < dateNow && usertype === false) {
        dispatch(checkValidation());
      }
    }
  };

  const updateCustomerFeedback = (data) => {
    dispatch(setCustomerFeedback(data));
  };

  const updateSublobDataState = (data) => {
    dispatch(setSublobDataState(data));
  };

  return {
    getQuote,
    quoteDetails,
    // fetchQuoteServiceDetails,
    getQuoteData,
    authtoken,
    autoPolicyDetails,
    updateCustomerFeedback,
    customerFeedback,
    paymentOptionsCheckState,
    additionalOptionsCheckState,
    otherCoveragesCheckState,
    paymentModeButtonStatus,
    seePaymentOptionButtonStatus,
    updateSublobDataState,
  };
};
