export const lobNameMap = {
  DWELLING: 'Your home quote',
  CONDO: 'Your condo quote',
  TENANT: 'Your renters quote',
  LANDLORDDWELLING: 'Your landlord home quote',
  LANDLORDCONDO: 'Your landlord condo quote',
  Auto: 'Your auto quote',
  Umbrella: 'Your umbrella quote',
  Personal_Article: 'Your personal article quote',
  Home: 'Home',
  Cooperative: 'Your cooperative quote',
};
