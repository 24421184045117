// #region imports for GlobalError
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ExclamationCircle124 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/exclamation-circle-1-24.svg';
import { GlobalHeader } from '../GlobalHeader/GlobalHeader';
import { Footer } from '../Footer/Footer';
import UmbrellaFooterPromo from '../../DigitalQuoteUI/UmbrellaPromoSection/UmbrellaFooterPromo';
// #endregion

export const GlobalError = (props) => {
  const { t } = useTranslation();
  const {
    title = t('globalError.defaultErrorTitle'),
    description = t('globalError.defaultDescription'),
  } = props;

  return (
    // #region GlobalError UI
    <>
      <GlobalHeader />
      <section className="" data-testid="global-error">
        <div className="tds-d-flex tds-flex-column tds-justify-content-center tds-align-items-center">
          <div className="tds-my-l">
            <ExclamationCircle124
              className="error-icon icon-color"
              alt="error"
              role="img"
            />
          </div>
          <h3 className="tds-text-align-center tds-mb-s">{title}</h3>
          {description ? (
            <p className="tds-text-align-center global-error-desc">
              {description}
            </p>
          ) : null}
        </div>
      </section>
      <UmbrellaFooterPromo />
      <Footer type="DQP" />
    </>
    // #endregion
  );
};

// #region PropTypes
GlobalError.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};
// #endregion
