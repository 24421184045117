import {
  GET_QUOTE_LIST_SUCCESS,
  GET_QUOTE_LIST_START,
  GET_QUOTE_LIST_ERROR,
} from '../actions/actionTypes';

const INITIAL_STATE = {
  quoteList: null,
  isLoading: false,
  errorMessage: null,
};
const quoteListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_QUOTE_LIST_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case GET_QUOTE_LIST_SUCCESS:
      return {
        ...state,
        quoteList: action.payload,
        isLoading: false,
        errorMessage: null,
      };
    case GET_QUOTE_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default quoteListReducer;
