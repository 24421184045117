import { Suspense, lazy } from 'react';
import { TdsLoadingSpinner } from '@trv-tds/react';
import { GlobalError } from './components/common/Error/GlobalError';
import { ROUTES, quoteid, PRODUCTION_ENV } from './constants';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { BoatDetailsPage } from './components/DigitalQuoteUI/BoatYachtUI/BoatDetailsPage/BoatDetailsPage';
import ScrollToTop from './components/ScrollToTop';

const QuoteList = lazy(() => import('./components/Admin/DQPQuoteList'));
const RawQuote = lazy(() => import('./components/Admin/RawQuote'));
const DigitalQuoteContainer = lazy(() =>
  import(
    './components/DigitalQuoteUI/DigitalQuoteContainer/DigitalQuoteContainer'
  )
);
const ThankYou = lazy(() => import('./components/ThankYouPage/ThankYou'));

export const DigitalQuoteRoutes = () => {
  return (
    <Router>
      <div>
        <Suspense
          fallback={
            <TdsLoadingSpinner
              size="xx-large"
              centered={true}
            ></TdsLoadingSpinner>
          }
        >
          <ScrollToTop />
          <Routes>
            <Route
              exact
              path={ROUTES.LANDING_PAGE}
              element={<DigitalQuoteContainer />}
            />

            {(process.env.NODE_ENV.toLowerCase() !== PRODUCTION_ENV ||
              process.env.REACT_APP_ENV?.toLowerCase() !== PRODUCTION_ENV) && (
              <>
                <Route
                  exact
                  path={ROUTES.DQP_QUOTELIST_PAGE}
                  element={<QuoteList />}
                />
                <Route
                  exact
                  path={ROUTES.RAW_QUOTELIST_PAGE}
                  element={<RawQuote />}
                />
              </>
            )}
            <Route
              exact
              path={ROUTES.THANK_YOU_QUOTES + quoteid + ROUTES.THANK_YOU_THANKS}
              element={<ThankYou />}
            />
            <Route
              exact
              path={ROUTES.BOAT_DETAILS_PAGE}
              element={<BoatDetailsPage />}
            />
            <Route
              exact
              path={'/' + quoteid + ROUTES.ERROR}
              element={<GlobalError />}
            />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
};
