import {
  QUOTE_SERVICE,
  UPDATE_CUSTOMER_FEEDBACK,
  UPDATE_SUBLOB_STATUS,
} from '../actions/actionTypes';

const getCommonDataOfSublobs = () => {
  return {
    additionalOptions: {},
    paymentOptionCheck: false,
    accordionItemsStatus: {},
    viewPolicyButtonStatus: false,
    paymentModeButton: false,
    seePaymentOptions: false,
  };
};

const initialState = {
  quoteDetails: null,
  electronicDeliveryCheck: false,
  customerFeedback: '',
  dwelling: {
    ...getCommonDataOfSublobs(),
  },
  tenant: {
    ...getCommonDataOfSublobs(),
  },
  landlordcondo: {
    ...getCommonDataOfSublobs(),
  },
  landlorddwelling: {
    ...getCommonDataOfSublobs(),
  },
  condo: {
    ...getCommonDataOfSublobs(),
  },
  auto: {
    ...getCommonDataOfSublobs(),
  },
  personal_article: { ...getCommonDataOfSublobs() },
  umbrella: { ...getCommonDataOfSublobs() },
  otherCoveragesOptions: {},
};

const quoteReducer = (state = initialState, action) => {
  let updatedState;
  switch (action.type) {
    case QUOTE_SERVICE:
      return {
        ...initialState,
        quoteDetails: action.payload,
      };
    case UPDATE_CUSTOMER_FEEDBACK:
      return {
        ...state,
        customerFeedback: action.payload,
      };
    case UPDATE_SUBLOB_STATUS:
      if (action?.payload?.sublob) {
        let sublobData = state[action.payload.sublob.toLowerCase()];
        if (typeof sublobData[action.payload.key] === 'object') {
          let sublobDataObj = sublobData[action.payload.key];
          updatedState = {
            ...state,
            [action.payload.sublob.toLowerCase()]: {
              ...sublobData,
              [action.payload.key]: {
                ...sublobDataObj,
                [action.payload.optionKey]: action.payload.optionValue,
              },
            },
          };
        } else {
          updatedState = {
            ...state,
            [action.payload.sublob.toLowerCase()]: {
              ...state[action.payload.sublob.toLowerCase()],
              [action.payload.key]: action.payload.keyValue,
            },
          };
        }
      } else {
        if (typeof state[action.payload.key] === 'object') {
          updatedState = {
            ...state,
            otherCoveragesOptions: {
              ...state.otherCoveragesOptions,
              [action.payload.optionKey]: action.payload.optionValue,
            },
          };
        } else {
          updatedState = {
            ...state,
            [action.payload.key]: action.payload.keyValue,
          };
        }
      }
      return updatedState;
    default:
      return state;
  }
};

export default quoteReducer;
