import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const getSpinner = (label) => (
  <div
    className="tds-loading-spinner__centered"
    aria-live="polite"
    aria-busy="true"
    role="status"
  >
    <div
      className="tds-loading-spinner tds-loading-spinner--x-large"
      id="tds-loading-spinner--default-centered"
    ></div>
    <label
      className="tds-loading-spinner__label"
      htmlFor="tds-loading-spinner--default-centered-example"
    >
      {label}
    </label>
  </div>
);

export const LoadingSpinner = ({ label, type }) => {
  const { t } = useTranslation();
  label = label ?? t('loadingSpinner.defaultLabel');

  return (
    <>
      {type === 'overlay' && (
        <div className="tds-loading-spinner__overlay background-white">
          {getSpinner(label)}
        </div>
      )}
      {type === 'basic' && (
        <div className="tds-container position-relative">
          {getSpinner(label)}
        </div>
      )}
    </>
  );
};

LoadingSpinner.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
};

LoadingSpinner.defaultProps = {
  type: 'overlay',
};
