export const DIGITAL_QUOTE_ID = 'Dqp_quote_id';
export const SAVE_POLICY_DETAILS = 'Save_policy_details';
export const QUOTE_SERVICE = 'Quote_service';
export const FETCH_POLICY_DETAILS_START = 'Save_policy_details_start';
export const FETCH_POLICY_DETAILS_SUCCESS = 'Save_policy_details_success';
export const FETCH_POLICY_DETAILS_ERROR = 'Save_policy_details_error';
export const SAVE_QUOTEID = 'Save_quoteId';
export const UPDATE_QUOTEID_AUTHTOKEN = 'Update_quoteId_authtoken_Success';
export const UPDATE_QUOTEID_AUTHTOKEN_ERROR = 'Update_quoteId_authtoken_Error';
export const UPDATE_QUOTEDETAILS = 'Update_quoteDetails';
export const GET_QUOTE_LIST_START = 'Get_quote_list_start';
export const GET_QUOTE_LIST_SUCCESS = 'Get_quote_list_success';
export const GET_QUOTE_LIST_ERROR = 'Get_quote_list_error';
export const UPDATE_LAST_KEY = 'Update_lastKey';
export const GET_RAW_DATA_START = 'Get_raw_data_start';
export const GET_RAW_DATA_SUCCESS = 'Get_raw_data_success';
export const GET_RAW_DATA_ERROR = 'Get_raw_data_error';
export const UPDATE_CUSTOMER_FEEDBACK = 'Update_customer_feedback';
export const UPDATE_SUBLOB_STATUS = 'Update_sublob_status';
