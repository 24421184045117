import { TdsGlobalFooter, TdsGlobalFooterLinks } from '@trv-tds/react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import './Footer.scss';

export const Footer = (props) => {
  const { t } = useTranslation();
  return (
    // #region Footer UI
    <>
      <TdsGlobalFooter
        sociallinks="true"
        className={
          props?.type == 'landing'
            ? 'umbrellaFooterLanding'
            : 'umbrellaFooterDQP'
        }
      >
        <TdsGlobalFooterLinks slot="links"></TdsGlobalFooterLinks>
        {props?.type == 'landing' && (
          <p slot="disclaimer" className="description">
            {t('landFooter.description')}
          </p>
        )}

        {props?.type == 'DQP' &&
          props?.underwritingCompany?.map((element) => {
            let lob = '';
            if (element.lob === 'INMRP') {
              lob = 'PAF';
            } else if (element.lob === 'UMBRP') {
              lob = 'Umbrella';
            } else if (element.lob === 'AUTO') {
              lob = 'Auto';
            } else if (element.lob === 'HOME') {
              lob = 'Property';
            } else if (element.lob === 'BOAT') {
              lob = 'Boat';
            }
            return (
              <p
                key={element.lob}
                slot="disclaimer"
                data-testid="disclaimerOne"
                className="disclaimerOne"
              >
                {lob !== 'Default' &&
                  t('dqpFooter.disclaimer.disclaimerOne', {
                    lob: lob,
                    company: element.description,
                  })}
                {lob === 'Default' && t('dqpFooter.disclaimer.default')}
              </p>
            );
          })}
        {props.type == 'DQP' && (
          <p slot="disclaimer">
            {t('dqpFooter.disclaimer.disclaimerTwo', {
              generatedDate: props.generatedDate,
            })}
          </p>
        )}
      </TdsGlobalFooter>
    </>
    // #endregion
  );
};

// #region PropTypes
Footer.propTypes = {
  type: PropTypes.string,
  lob: PropTypes.string,
  generatedDate: PropTypes.string,
  underwritingCompany: PropTypes.array,
};
// #endregion
