import PropTypes from 'prop-types';
import React from 'react';
import './styles/Modal.scss';
import ReactHtmlParser from 'html-react-parser';
const ModalBodyContent = (props) => {
  let modalDataDetails;
  // eslint-disable-next-line react/prop-types
  let lob = props?.lob;
  if (typeof props?.description === 'string') {
    const modalDescriptionDetails = props?.description?.includes('<LIST>');
    if (modalDescriptionDetails === true) {
      const modalDescription = props?.description?.split('<LIST>');
      const modalBody = React.createElement(
        'div',
        { key: 'modalBody' },
        modalDescription[0]
      );
      const modalBodyContentData = React.createElement(
        'div',
        { key: 'modalBodyContentData' },
        modalDescription[2]
      );
      const listDescription = modalDescription[1].split('*');
      const modalData = (
        <ul key={'modalData'}>
          {listDescription?.map((ele) => (
            <li key={ele}>{ele.replace(/\n/g, '')}</li>
          ))}
        </ul>
      );
      modalDataDetails = React.createElement(
        'div',
        { key: 'modalDataDetails' },
        [modalBody, modalData, modalBodyContentData]
      );
    } else {
      modalDataDetails = props?.description;
    }
  } else {
    {
      modalDataDetails = props?.description;
    }
  }
  return (
    // #region ModalBodyContent UI
    <>
      {props?.description && (
        <div className="tds-text-align-left content-alignment ">
          {lob == 'Boat' ? ReactHtmlParser(modalDataDetails) : modalDataDetails}
        </div>
      )}

      {props.video && (
        <div className="tds-mt-xs modal-Content">
          <iframe
            src={props.video}
            width={props.width}
            height={props.height}
            className="video-content"
          />
        </div>
      )}
    </>
    // #endregion
  );
};

// #region PropTypes
ModalBodyContent.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  video: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};
// #endregion

export default ModalBodyContent;
