import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export const LandTransportationDetails = (props) => {
  const { t } = useTranslation();
  const {
    beginningCity,
    beginningState,
    destinationCity,
    destinationState,
    totalMiles,
    dateOfDeparture,
    dateOfArrival,
  } = props ?? {};
  return (
    <div className="tds-container">
      {beginningCity && beginningState && (
        <div className="tds-d-flex">
          <div
            data-testid="landTransportation-departure-label"
            className="tds-col-md-3"
          >
            {t('coverages.landTransportationDeparture')}
            {':'}
          </div>
          <div
            data-testid="landTransportation-departure"
            className="tds-col-md-auto tds-bold"
          >
            {beginningCity}, &nbsp;{beginningState}
          </div>
        </div>
      )}
      {destinationCity && destinationState && (
        <div className="tds-d-flex">
          <div
            data-testid="landTransportation-destination-label"
            className="tds-col-md-3"
          >
            {t('coverages.landTransportationDestination')}
            {':'}
          </div>
          <div
            data-testid="landTransportation-destination"
            className="tds-col-md-auto tds-bold"
          >
            {destinationCity}, &nbsp;{destinationState}
          </div>
        </div>
      )}
      {totalMiles && (
        <div className="tds-d-flex">
          <div
            data-testid="landTransportation-mileage-label"
            className="tds-col-md-3"
          >
            {t('coverages.landTransportationMileage')}
            {':'}
          </div>
          <div
            data-testid="landTransportation-mileage"
            className="tds-col-md-auto tds-bold"
          >
            {totalMiles}
            {t('coverages.landTransportationMiles')}
          </div>
        </div>
      )}
      {dateOfDeparture && (
        <div className="tds-d-flex">
          <div
            data-testid="landTransportation-departure-date-label"
            className="tds-col-md-3"
          >
            {t('coverages.landTransportationDepartureDate')}
            {':'}
          </div>
          <div
            data-testid="landTransportation-departure-date"
            className="tds-col-md-auto tds-bold"
          >
            {dateOfDeparture}
          </div>
        </div>
      )}
      {dateOfArrival && (
        <div className="tds-d-flex">
          <div
            data-testid="landTransportation-arrival-date-label"
            className="tds-col-md-3"
          >
            {t('coverages.landTransportationArrivalDate')}
            {':'}
          </div>
          <div
            data-testid="landTransportation-arrival-date"
            className="tds-col-md-auto tds-bold"
          >
            {dateOfArrival}
          </div>
        </div>
      )}
    </div>
  );
};

LandTransportationDetails.propTypes = {
  landTransportationData: PropTypes.array,
};
