import env from 'env-var';

const NODE_ENV = env.get('NODE_ENV').asString();

const PUBLIC_URL = env.get('PUBLIC_URL').asString();

const REACT_APP_SIMPLE_QUOTE_API_URL = env
  .get('REACT_APP_SIMPLE_QUOTE_API_URL')
  .asString();

const REACT_APP_SIMPLE_QUOTE_API_TARGET_URL = env
  .get('REACT_APP_SIMPLE_QUOTE_API_TARGET_URL')
  .asString();

const REACT_APP_JWT_KEY = env.get('REACT_APP_JWT_KEY').asString();

const REACT_APP_GACODE = env.get('REACT_APP_GACODE').asString();

export {
  NODE_ENV,
  PUBLIC_URL,
  REACT_APP_SIMPLE_QUOTE_API_URL,
  REACT_APP_JWT_KEY,
  REACT_APP_GACODE,
  REACT_APP_SIMPLE_QUOTE_API_TARGET_URL,
};
