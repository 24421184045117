// #region imports for Header
import { TdsGlobalHeader, TdsButton } from '@trv-tds/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './GlobalHeader.scss';
import { ClickEvents } from '../../../utils/analytics';
import { policyIconNameMap } from '../../../utils';
import { TRAV_ACORD_VERSION_2 } from '../../../constants';
// #endregion

export const GlobalHeader = (props) => {
  const { t } = useTranslation();

  const handlePrintBtnClick = () => {
    ClickEvents(t('GAConstants.printButton'));
    props?.handlePrintClick();
  };

  return (
    // #region Header UI
    <TdsGlobalHeader homehref="#" homelabel="globalHeader" role="banner">
      <span slot="logo" className="tds-d-flex">
        <em>{policyIconNameMap.BlackSmall}</em>
        <div className="tds-global-header__product">
          {t('header.digitalQuoteProposal')}
        </div>
      </span>

      {props.type == 'DQP' &&
        props?.travAcordVersion !== TRAV_ACORD_VERSION_2 &&
        props.version != '2.0' && (
          <TdsButton
            slot="actions"
            size="small"
            label="print"
            title="PRINT QUOTE"
            variant="secondary"
            data-testid="printQuote"
            className="tds-button-icon"
            onClick={handlePrintBtnClick}
          >
            <span className="tds-button-textContent">
              {t('header.printQuote')}
            </span>
            <span>{policyIconNameMap.Printer}</span>
          </TdsButton>
        )}
    </TdsGlobalHeader>
    // #endregion
  );
};

// #region PropTypes
GlobalHeader.propTypes = {
  type: PropTypes.string,
  handlePrintClick: PropTypes.func,
  version: PropTypes.any,
  travAcordVersion: PropTypes.string,
};
// #endregion
