// #region imports for Header
import { TdsButton } from '@trv-tds/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './Header.scss';
import { ClickEvents } from '../../../utils/analytics';
import { policyIconNameMap } from '../../../utils';
import { TRAV_ACORD_VERSION_2 } from '../../../constants';
import { Link, useNavigate } from 'react-router-dom';
// #endregion

export const Header = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleTravLogoClick = () => {
    ClickEvents(t('GAConstants.travelersLogo'));
    navigate(-1);
  };
  const handlePrintBtnClick = () => {
    ClickEvents(t('GAConstants.printButton'));
    props?.handlePrintClick();
  };

  return (
    // #region Header UI
    <div>
      <section data-testid="header-logo-text-section">
        <div className="tds-ml-auto">
          <Link
            className="tds-mt-l tds-mb-s logo-header-text"
            data-testid="header-logo"
            onClick={handleTravLogoClick}
          >
            <span slot="logo" className="tds-d-flex logo-text-span-align">
              <em>{policyIconNameMap.BlackSmall}</em>
              <div
                className="tds-global-header__product"
                data-testid="header-text"
                alt=""
              >
                {t('header.digitalQuoteProposal')}
              </div>
            </span>
          </Link>
        </div>
      </section>
      <section data-testid="header-printbutton-section">
        <div className="tds-mr-auto">
          {props?.type === 'DQP' &&
            props?.travAcordVersion !== TRAV_ACORD_VERSION_2 && (
              <div className="print-btn-align">
                <TdsButton
                  slot="actions"
                  size="small"
                  label="print"
                  title="PRINT QUOTE"
                  variant="secondary"
                  data-testid="print-btn"
                  className="tds-button-icon"
                  onClick={handlePrintBtnClick}
                >
                  <span
                    className="tds-button-textContent"
                    data-testid="print-btn-text"
                  >
                    {t('header.printQuote')}
                  </span>
                  <span data-testid="print-btn-icon">
                    {policyIconNameMap.Printer}
                  </span>
                </TdsButton>
              </div>
            )}
        </div>
      </section>
    </div>
    // #endregion
  );
};

// #region PropTypes
Header.propTypes = {
  type: PropTypes.string,
  handlePrintClick: PropTypes.func,
  version: PropTypes.any,
  travAcordVersion: PropTypes.string,
};
// #endregion
